<template>
    <modal ref="modalRetiroManual" titulo="Retiro Manual" :cargando="cargando" no-aceptar adicional="Retirar" @adicional="insert_retiro">
        <ValidationObserver ref="validator">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col px-0">
                    <div class="row mx-0">
                        <ValidationProvider v-slot="{errors}" :rules="`required|${producto.cantidad_tipo == 1 ? 'numeric' : 'decimal:2'}`" tag="div" class="col-6" name="cantidad">
                            <label class="pl-3 f-15"> Cantidad</label>
                            <el-input v-model="model.cantidad" class="w-100" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{errors}" rules="required" tag="div" class="col-6" name="motivo">
                            <label class="pl-3 f-15"> Motivo de retiro</label>
                            <el-select v-model="model.motivo" placeholder="Seleccione" class="w-100">
                                <el-option
                                v-for="(item, i) in motivos"
                                :key="i"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                        <div class="col-12 mt-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="justificación">
                                <label class="pl-3 f-15"> Observación del retiro </label>
                                <el-input v-model="model.comentario" type="textarea" :rows="4" maxlength="250" show-word-limit />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import ProductosStocks from '~/services/productos/productos_stocks'
import motivosRetiros from '~/services/configurar/admin/motivosRetiros'


export default {
    data(){
        return{
            cargando:false,
            model:{

                cantidad: null,
                comentario: null,
                id_cedis: null,
                tipo: 1,
                motivo: ''
            },
            producto:{},
            motivos: [],

        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            id_cedis:'cedis/id_cedis'

        }),
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(producto){
            this.producto = producto
            this.limpiarForm()
            this.listarMotivos()
            this.$refs.modalRetiroManual.toggle();
        },
        async listarMotivos(){
            try {
                let datos
                if(this.id_cedis){
                    const {data} = await motivosRetiros.listarRetiroCedis()
                    datos = data
                }
                if(this.id_tienda){
                    const {data} = await motivosRetiros.listarRetiroLeecheros()
                    datos = data
                }
                this.motivos = datos
            } catch (e){
                this.error_catch(e)
            }
        },
        async insert_retiro(){
            try {
                const valid = await this.$refs.validator.validate()

                if(valid){
                    this.cargando = true
                    this.model.id_producto = this.id_producto

                    if(this.id_cedis){
                        this.model.id_cedis = this.id_cedis
                        await  ProductosStocks.retiroCedis(this.model)
                    }
                    if(this.id_tienda){
                        this.model.id_tienda = this.id_tienda
                        await  ProductosStocks.retiroTienda(this.model)
                    }
                    this.cargando = false
                    this.notificacion('Mensaje', 'Retiro realizado', 'success')
                    this.$emit('actualizar')
                    this.$refs.modalRetiroManual.toggle();
                }

            } catch (e){
                this.cargando = false
                this.error_catch(e)
            }
        },
        limpiarForm(){
            this.model={
                cantidad: null,
                comentario: null,
                tipo: 1
            }
            this.max_valor= 0
        }
    }
}
</script>

<style>

</style>
